<template>
  <div class="booking_split_detail popup_wrap" style="width:900px; height:530px;">
    <!-- popup_wrap :  style="width:900px; height:600px;" 참고용 / 개발시 삭제 해주 세요. . -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.CSBK100.256') }}</h1> <!-- Booking Split -->

      <div class="content_box mt10">
        <!-- content_box -->

        <div class="flex_box">
          <h2 class="content_title">{{ $t('msg.CSBK100.257') }}</h2> <!-- Booking Information -->
          <div class="bkbl_no tbl_topright">
            <span class="title">{{ $t('msg.CSBK100.258') }}</span> <!-- Booking No. -->
            <span class="txt_bar2"></span>
            <span>{{ bkgNo }}</span>
          </div>
        </div>

        <table class="tbl_row">
          <colgroup>
            <col width="130"><col><col width="130"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBK100.259') }}</th> <!-- 출발(T/S) -->
              <td>
                <ul class="schedule_info">
                  <li>{{ objBookingDetailMain.porPlcEnm }}</li>
                  <li>
                    <!-- ETD : -->
                    {{ $t('msg.CSBK100.260') }} : {{ etd }}
                  </li>
                </ul>
              </td>
              <th>{{ $t('msg.CSBK100.030') }}</th> <!-- 도착 -->
              <td>
                <ul class="schedule_info">
                  <li>{{ objBookingDetailMain.dlyPlcEnm }}</li>
                  <li>
                    <!-- ETA : -->
                    {{ $t('msg.CSBK100.261') }} : {{ eta }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBK100.262') }}</th> <!-- 선명/항차 -->
              <td>
                {{ objBookingDetailMain ? (objBookingDetailMain.vslNm + '/' + objBookingDetailMain.voyNo) : '' }}
              </td>
              <th>{{ $t('msg.CSBK100.011') }}</th> <!-- 실화주 -->
              <td>
                <input type="text"
                       id="txtRealCompany"
                       name="txtRealCompany"
                       :value="objBookingDetailMain.actShprCstEnm"
                       maxlength="30"
                       disabled
                />
                <span class="float_right" v-if="lang == 'KOR'">
                  <input type="checkbox" id="consYn" name="consYn"
                         :checked="objBookingDetailMain.consYn == 'Y'"
                         disabled
                  />
                  <label for="consYn"><span></span>{{ $t('msg.CSBK100.263') }}</label> <!-- 콘솔부킹 -->
                </span>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBK100.030-1') }}</th> <!-- Term -->
              <td>
                <div v-if="cstCatCd != 'Y'" class="tbl_form">
                  <!-- 일반 화주일 때-->
                  <span class="dv">
                    <select
                      name="cboTerm"
                      id="cboTerm"
                      v-model="objBookingDetailMain.cboTerm"
                      @click.prevent="fnChangeType($event, 'cboTerm')"
                      disabled
                    >
                      <option value="01">CY</option>
                      <option value="02">CFS</option>
                      <option value="03">CY&CFS</option>
                    </select>
                  </span>
                  <span class="dv">
                    <select name="podTerm" id="podTerm" v-model="objBookingDetailMain.podShtmCd" disabled>
                      <option value="01">CY</option>
                      <option value="02">CFS</option>
                    </select>
                  </span>
                </div>

                <div v-else class="tbl_form">
                  <!-- SOC 화주일 때-->
                  <span class="dv">
                    <select name="cboTerm" id="cboTerm" v-model="objBookingDetailMain.cboTerm">
                      <option value="01">CY</option>
                      <option value="02">Tackle</option>
                      <option value="03">FI</option>
                    </select>
                  </span>
                  <span class="dv">
                    <select name="podTerm" id="podTerm" v-model="objBookingDetailMain.podShtmCd">
                      <option value="01">CY</option>
                      <option value="05">Tackle</option>
                      <option value="07">FO</option>
                      <option value="09">T/S Zone</option>
                    </select>
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBK100.027-1') }}</th> <!-- Booking Date -->
              <td>{{ bookingDate }}</td>
            </tr>

            <tr v-if="lang == 'JPN' && fwdrCstCd == '01'">
              <!-- 일본인 경우 -->
              <th>{{ $t('msg.CSBK100.264') }}</th> <!-- Booking Shipper -->
              <td colspan="3">
                <select name="bkgShprCstNo" v-model="objBookingDetailMain.bkgShprCstNo">
                  <option value="">{{ $t('msg.CSBK100.050') }}</option> <!-- 직접입력 -->
                  <option
                    v-for="(item, index) in bkgShprList"
                    :key="index"
                    :selected="item.bkgShprCstNo == objBookingDetailMain.bkgShprCstNo"
                  >
                    {{ item.bkgShprCstEnm }}
                  </option>
                </select>
                &nbsp;&nbsp;&nbsp; <input type="text" class="input txt" style="width:220px;" name="bkgShprCstEnm" :value="objBookingDetailMain.bkgShprCstEnm" maxlength="50" @blur="this.value=this.value.toUpperCase()">
                <br><font color="red">* 弊社と運賃のご契約をされた方を意味します。</font>
              </td>
            </tr>
            <tr v-if="lang == 'JPN'">
              <th>通関業者</th>
              <td colspan="3">
                <select name="fwderSelect" id="fwderSelect" v-model="objBookingDetailMain.fwdrCstNo">
                  <option value="">{{ $t('msg.CSBK100.050') }}</option> <!-- 직접입력 -->
                  <option
                    v-for="(item, index) in fwdrList"
                    :key="index"
                    :selected="fwdrList.fwdrCstNo == objBookingDetailMain.fwdrCstNo"
                  >
                    {{ item.fwdrCstEnm }}
                  </option>
                </select>
                &nbsp;&nbsp;&nbsp; <input type="text" class="input txt" style="width:220px;" name="fwdrCstEnm" id="fwdrCstEnm" :value="objBookingDetailMain.fwdrCstEnm" maxlength="50" @blur="this.value=this.value.toUpperCase()" :readOnly="cstYn">
                <input type="hidden" class="input txt" name="fwdrCstNo" id="fwdrCstNo" :value="objBookingDetailMain.fwdrCstNo">
              </td>
            </tr>

            <tr>
              <th>{{ $t('msg.CSBK100.267') }}</th> <!-- Commodity -->
              <td>
                <input type="text" :value="objBookingDetailMain.cmdtDsc" disabled>
              </td>
              <th>{{ $t('msg.CSBK100.268') }}</th> <!-- Gross Weight -->
              <td>
                <span class="wg"> <!-- ton -->
                  <e-input-number
                    id="splitGrsWtTon"
                    v-model="grsWtTon"
                    :is-comma="true"
                    :input-class="'wid80'"
                    :point="3"
                    maxlength="30"
                    disabled
                  >
                  </e-input-number> {{ $t('msg.CSBK100.269') }}
                </span>
                <span class="color_gray span_grs">/</span>
                <span class="wg"> <!-- kg -->
                  <e-input-number
                    id="splitGrsWtKg"
                    v-model="grsWtKg"
                    :is-comma="true"
                    :input-class="'wid80'"
                    maxlength="30"
                    disabled
                  >
                  </e-input-number> {{ $t('msg.CSBK100.270') }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- @@@@@@@@ CONTAINER INFORMATION @@@@@@@@ -->
        <h2 class="content_title">{{ $t('msg.CSBK100.271') }}</h2> <!-- Container Information -->
        <table class="tbl_col">
          <colgroup>
            <col style="width:10%">
            <col style="width:18%">
            <col style="width:10%">
            <col style="width:10%">
            <col style="width:8%">
            <col style="width:20%">
            <col style="width:24%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBK100.274') }}</th> <!-- Q'ty -->
              <th>{{ $t('msg.CSBK100.275') }}</th> <!-- Term -->
              <template v-if="objBookingDetailMain.polShtmCd == '01'">
                <th>{{ $t('msg.CSBK100.276') }}</th> <!-- Pick Up 일시 -->
                <th>{{ $t('msg.CSBK100.277') }}</th> <!-- Pick Up 장소 -->
                <th>{{ $t('msg.CSBK100.278') }}</th> <!-- Pick Up 담당자 -->
              </template>
              <template v-if="objBookingDetailMain.polShtmCd == '02'">
                <th>{{ $t('msg.CSBK100.279') }}</th> <!-- 입고일자 -->
                <th colspan="2">{{ $t('msg.CSBK100.280') }}</th> <!-- 쇼링 업체 (쇼링업체명 기재) -->
              </template>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(list, i) in objBookingDetailContainer" :key="i">
              <td>
                <!-- Size -->
                <select
                  :name="'containerSize_' + (i + 1)"
                  :id="'containerSize_' + (i + 1)"
                  v-model="list.cntrSzCd"
                  disabled
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="45">45</option>
                </select>
              </td>
              <td>
                <!-- Type -->
                <select :name="'containerType_' + (i + 1)" :id="'containerType_' + (i + 1)" disabled>
                  <option value="0"></option>
                  <option v-for="(cntrType,subIndex) in cntrTypeList"
                          :key="subIndex"
                          :value="cntrType.cd"
                          :selected="list.cntrTypCd === cntrType.cd"
                  >
                    {{ cntrType.cdNm }}
                  </option>
                </select>
              </td>
              <td>{{ list.cntrQty }}</td> <!-- Q'ty -->
              <td>
                <!-- Term -->
                <select
                  :name="'containerPolTerm_' + (i + 1)"
                  :id="'containerPolTerm_' + (i + 1)"
                  v-model="objBookingDetailMain.cboTerm"
                  disabled
                >
                  <option value="01">CY</option>
                  <option value="02">CFS</option>
                </select>
              </td>
              <td>
                <!-- Pick Up 일시 -->
                <div class="input_calendar mid">
                  <e-date-picker
                    v-model="list.pcupReqDt"
                    :id="'pickUpDate_' + (i + 1)"
                    :name="'pickUpDate_' + (i + 1)"
                    :disabled="true"
                  >
                  </e-date-picker>
                </div>
              </td>
              <td v-if="objBookingDetailMain.polShtmCd == '01'" :id="'pickUpTable_' + (i + 1)">
                <!-- Pick Up 장소 -->
                <p>
                  <input type="text"
                         :id="'pickUpPlace_' + (i + 1)"
                         :name="'pickUpPlace_' + (i + 1)"
                         :value="list.pcupCyNm"
                         :disabled="true"
                  >
                </p>
              </td>

              <td v-if="objBookingDetailMain.polShtmCd == '02'" :id="'pickUpTable_' + (i + 1)"
                  :colspan="objBookingDetailMain.polShtmCd == '02' ? 2 : 1"
              >
                <template v-if="i === 0">
                  <select :name="'cfsFixdTypCd_' + (i + 1)"
                          :id="'cfsFixdTypCd_' + (i + 1)"
                          v-model="objBookingDetailMain.cfsFixdTypCd"
                          :disabled="true"
                  >
                    <option value="01">{{ $t('msg.CSBK100.047') }}</option> <!-- Line -->
                    <option value="02">{{ $t('msg.CSBK100.048') }}</option> <!-- 자가 -->
                    <option value="03">{{ $t('msg.CSBK100.283') }}</option> <!-- 쇼링없음 -->
                  </select>
                  <input type="text" name="cfsFixdEntrNm"
                         id="cfsFixdEntrNm"
                         v-model="objBookingDetailMain.cfsFixdEntrNm"
                         :disabled="objBookingDetailMain.cfsFixdTypCd != '02'"
                  > <!-- 선적항 CFS일 때 -->
                </template>
                <template v-else>
                  <p>-</p>
                </template>
              </td>

              <td v-if="objBookingDetailMain.polShtmCd == '01'">
                <!-- Pick Up 담당자 -->
                <div class="tbl_form">
                  <span class="dv col_3 font_12 text_left"> <!-- 담당자 -->
                    {{ $t('msg.CMBK100_M1.023_1') }}
                  </span>
                  <span class="dv">
                    <input type="text"
                           :name="'pickUpMan_' + (i + 1)"
                           :id="'pickUpMan_' + (i + 1)"
                           :value="list.pcupMan"
                           :disabled="true"
                    >
                  </span>
                </div>
                <div class="tbl_form mt5">
                  <span class="dv col_3 font_12 text_left">
                    {{ $t('msg.CSBK100.285') }} <!-- Tel -->
                  </span>
                  <span class="dv">
                    <input type="text"
                           :name="'pickUpTel_' + (i + 1)"
                           :id="'pickUpTel_' + (i + 1)"
                           :value="list.pcupTel"
                           :disabled="true"
                    >
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- @@@@@@@@ SPLIT INFORMATION @@@@@@@@ -->
        <h2 class="content_title">{{ $t('msg.CSBK100.286') }}</h2> <!-- Split Information -->
        <template v-if="editFlag != 'Y'">
          <table class="tbl_col mt10" v-for="(main, index) in objBookingDetailMainList" :key="index">
            <colgroup>
              <col style="width:6%">
              <col style="width:10%">
              <col style="width:12%">
              <col style="width:10%">
              <col style="width:10%">
              <col style="width:8%">
              <col style="width:20%">
              <col style="width:24%">
            </colgroup>
            <tr>
              <th rowspan="2">
                <button v-if="index === 0" class="tbl_icon plus" @click="fnChangeMain('PLUS', -1)">plus</button>
                <button v-else :class="{tbl_icon: true, minus: true, disabled: main.fixFlag}" @click="fnChangeMain('MINUS', index)">minus</button>
              </th>
              <th>{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
              <th colspan="2">{{ $t('msg.CSBK100.268') }}</th> <!-- Gross Weight -->
              <th colspan="2">{{ $t('msg.CSBK100.258') }}</th> <!-- Booking No. -->
              <th colspan="2"></th>
            </tr>
            <tr>
              <td>{{ index + 1 }}</td>
              <td colspan="2">
                <input type="text" class="wid100"
                       :id="'grsWt_' + index"
                       :name="'grsWt_' + index" v-model="main.eachGrsWt"
                       @blur.prevent="chkGrossWeight(main.eachGrsWt, index)"
                > kg
              </td>
              <td colspan="2">
                {{ index === 0 ? bkgNo : '' }}
              </td>
              <td colspan="2">
              </td>
            </tr>
            <tr>
              <th rowspan="2">
                <button class="tbl_icon plus" @click="fnChangeSub('PLUS', index, -1)">plus</button>
              </th>
              <th class="border_left">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBK100.274') }}</th> <!-- Q'ty -->
              <th>{{ $t('msg.CSBK100.275') }}</th> <!-- Term -->
              <template v-if="main.polShtmCd === '01'">
                <th>{{ $t('msg.CSBK100.276') }}</th> <!-- Pick Up 일시 -->
                <th>{{ $t('msg.CSBK100.277') }}</th> <!-- Pick Up 장소 -->
                <th>{{ $t('msg.CSBK100.278') }}</th> <!-- Pick Up 담당자 -->
              </template>
              <template v-if="main.polShtmCd === '02'">
                <th>{{ $t('msg.CSBK100.279') }}</th> <!-- 입고일자 -->
                <th colspan="2">{{ $t('msg.CSBK100.280') }}</th> <!-- 쇼링 업체 (쇼링업체명 기재) -->
              </template>
            </tr>
            <template v-for="(cntrList, i) in objBookingDetailContainerList">
              <template v-for="(cntr, k) in cntrList">
                <tr :key="k" v-if="index == i">
                  <td v-if="k!==0"><button class="tbl_icon minus" @click="fnChangeSub('MINUS', index, k)">minus</button></td>
                  <td class="border_left">
                    <select :name="'cntrSzCd_' + i + '_' + k" :id="'cntrSzCd_' + i + '_' + k" v-model="cntr.newCntrSzCd">
                      <option value="20">20</option>
                      <option value="40">40</option>
                    </select>
                  </td>
                  <td>
                    <select :name="'cntrTypCd_' + i + '_' + k" :id="'cntrTypCd_' + i + '_' + k" v-model="cntr.newCntrTypCd">
                      <option value="GP">Dry</option>
                      <option value="HC">HIGH CUBE</option>
                    </select>
                  </td>
                  <td>
                    <input type="text" :id="'cntrQty_' + i + '_' + k" :name="'cntrQty_' + i + '_' + k" v-model="cntr.newCntrQty">
                  </td>
                  <td>
                    <select :name="'cntrTerm_' + i + '_' + k" :id="'cntrTerm_' + i + '_' + k" disabled>
                      <option value="01" :selected="i == 0 && k == 0? objBookingDetailMain.polShtmCd == '01' : cntr.polShtmCd == '01'">CY</option>
                      <option value="02" :selected="i == 0 && k == 0 ? objBookingDetailMain.polShtmCd == '02' : cntr.polShtmCd == '02'">CFS</option>
                    </select>
                  </td>
                  <td>
                    <!-- Pick Up 일시 -->
                    <div class="input_calendar mid">
                      <e-date-picker
                        v-model="cntr.newPcupReqDt"
                        :id="'pickUpDate_' + i +'_' + k"
                        :name="'pickUpDate_' + i +'_' + k"
                        @input="fnPickUpDateCheck(cntr.newPcupReqDt, i, k)"
                        :disabled="cntr.shprVanYn === 'Y'"
                      >
                      </e-date-picker>
                    </div>
                  </td>
                  <td v-if="objBookingDetailMain.polShtmCd == '01'" :id="'pickUpTable_' + i">
                    <!-- 선적항 CY일 때-Pick Up 장소 -->
                    <p>
                      <input type="text"
                             :id="'pickUpPlace_' + i + '_' + k"
                             :name="'pickUpPlace_' + i + '_' + k"
                             v-model="cntr.newPcupCyNm"
                             @click="fnPickUpPlace(i, k)"
                             :disabled="cntr.shprVanYn === 'Y'"
                             :readonly="true"
                      >
                    </p>
                  </td>
                  <td v-if="objBookingDetailMain.polShtmCd == '02'"
                      :id="'pickUpTable_' + (k + 1)"
                      :colspan="objBookingDetailMain.polShtmCd == '02' ? 2 : 1"
                  >
                    <template v-if="k === 0">
                      <select :name="'cfsFixdTypCd_' + index"
                              :id="'cfsFixdTypCd_' + index"
                              v-model="main.newCfsFixdTypCd"
                              @change="fnChangeShowling(main.newCfsFixdTypCd, index)"
                      >
                        <option value="01">{{ $t('msg.CSBK100.047') }}</option> <!-- Line -->
                        <option value="02">{{ $t('msg.CSBK100.048') }}</option> <!-- 자가 -->
                        <option value="03">{{ $t('msg.CSBK100.283') }}</option> <!-- 쇼링없음 -->
                      </select>
                      <input type="text" :name="'cfsFixdEntrNm_' + index"
                             :id="'cfsFixdEntrNm_' + index"
                             v-model="main.newCfsFixdEntrNm"
                             :readonly="main.newCfsFixdEntrNm !== '02'"
                      > <!-- 선적항 CFS일 때 -->
                    </template>
                    <template v-else>
                      <p>-</p>
                    </template>
                  </td>

                  <td v-if="objBookingDetailMain.polShtmCd == '01'">
                    <div class="tbl_form">
                      <!-- 담당자 -->
                      <span class="dv col_3 font_12 text_left">
                        {{ $t('msg.CMBK100_M1.023_1') }}
                      </span>
                      <span class="dv">
                        <input type="text"
                               :name="'pickUpMan_' + i +'_' + k"
                               :id="'pickUpMan_' + i + '_' + k"
                               :value="cntr.pcupMan"
                               :disabled="true"
                        >
                      </span>
                    </div>
                    <div class="tbl_form mt5">
                      <!-- Tel -->
                      <span class="dv col_3 font_12 text_left">
                        {{ $t('msg.CSBK100.285') }}
                      </span>
                      <span class="dv">
                        <input type="text"
                               :name="'pickUpTel_' + i + '_' + k"
                               :id="'pickUpTel_' + i + '_' + k"
                               :value="cntr.pcupTel"
                               :disabled="true"
                        >
                      </span>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
          </table>
        </template>

        <!-- 굳이 필요한지 모르겠지만 우선 ASIS대로 처리-->
        <template v-else>
          <table class="tbl_col mt10" v-for="(main, index) in splitDetailList" :key="index">
            <colgroup>
              <col style="width:6%">
              <col style="width:10%">
              <col style="width:12%">
              <col style="width:10%">
              <col style="width:10%">
              <col style="width:8%">
              <col style="width:20%">
              <col style="width:24%">
            </colgroup>
            <tr>
              <th colspan="2">{{ $t('msg.CSBK100.287') }}</th> <!-- SEQ -->
              <th colspan="2">{{ $t('msg.CSBK100.268') }}</th> <!-- Gross Weight -->
              <th colspan="2">{{ $t('msg.CSBK100.258') }}</th> <!-- Booking No.-->
              <th colspan="2"></th>
            </tr>
            <tr>
              <td colspan="2">{{ index + 1 }}</td>
              <td colspan="2">
                <input type="text" class="wid100"
                       :id="'grsWt_' + index"
                       :name="'grsWt_' + index" v-model="main.grsWt"
                       :disabled="true"
                > kg
              </td>
              <td colspan="2">
                <a v-if="index === 0" @click="fnDetail(bkgNo)">{{ bkgNo }}</a>
                <a v-else @click="fnDetail(main.nwBkgNo)">{{ main.nwBkgNo }}</a>
              </td>
              <td colspan="2">
              </td>
            </tr>
            <tr>
              <th colspan="2" class="border_left">{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBK100.274') }}</th> <!-- Q'ty -->
              <th>{{ $t('msg.CSBK100.275') }}</th> <!-- Term -->
              <template v-if="objBookingDetailMain.polShtmCd === '01'">
                <th>{{ $t('msg.CSBK100.276') }}</th> <!-- Pick Up 일시 -->
                <th>{{ $t('msg.CSBK100.277') }}</th> <!-- Pick Up 장소 -->
                <th>{{ $t('msg.CSBK100.278') }}</th> <!-- Pick Up 담당자 -->
              </template>
              <template v-if="objBookingDetailMain.polShtmCd === '02'">
                <th>{{ $t('msg.CSBK100.279') }}</th> <!-- 입고일자 -->
                <th colspan="2">{{ $t('msg.CSBK100.280') }}</th> <!-- 쇼링 업체 (쇼링업체명 기재) -->
              </template>
            </tr>
            <template v-for="(cntr, i) in splitCntrList">
              <tr :key="i" v-if="main.bkgPrtlDtlSeq == cntr.bkgPrtlDtlSeq">
                <td colspan="2" class="border_left">
                  <select :name="'cntrSzCd_' + index + '_' + i" :id="'cntrSzCd_' + index + '_' + i" v-model="cntr.cntrSzCd" disabled>
                    <option value="20">20</option>
                    <option value="40">40</option>
                  </select>
                </td>
                <td>
                  <select :name="'cntrTypCd_' + index + '_' + i" :id="'cntrTypCd_' + index + '_' + i" v-model="cntr.cntrTypCd" disabled>
                    <option value="GP">Dry</option>
                    <option value="HC">HIGH CUBE</option>
                  </select>
                </td>
                <td>
                  <input type="text" :id="'cntrQty_' + index + '_' + i" :name="'cntrQty_' + index + '_' + i" v-model="cntr.cntrQty" disabled>
                </td>
                <td>
                  <select :name="'cntrTerm_' + index + '_' + i" :id="'cntrTerm_' + index + '_' + i" v-model="objBookingDetailMain.polShtmCd" disabled>
                    <option value="01">CY</option>
                    <option value="02">CFS</option>
                  </select>
                </td>
                <td>
                  <!-- Pick Up 일시 -->
                  <div class="input_calendar mid">
                    <e-date-picker
                      v-model="cntr.pcupReqDt"
                      :id="'pickUpDate_' + index +'_' + i"
                      :name="'pickUpDate_' + index +'_' + i"
                      :disabled="true"
                    >
                    </e-date-picker>
                  </div>
                </td>
                <td v-if="objBookingDetailMain.polShtmCd == '01'" :id="'pickUpTable_' + i">
                  <!-- 선적항 CY일 때-Pick Up 장소 -->
                  <p>
                    <input type="text"
                           :id="'pickUpPlace_' + index + '_' + i"
                           :name="'pickUpPlace_' + index + '_' + i"
                           v-model="cntr.pcupCyNm"
                           :disabled="true"
                    >
                  </p>
                </td>
                <td v-if="objBookingDetailMain.polShtmCd == '02'" :id="'pickUpTable_' + (i + 1)"
                    :colspan="objBookingDetailMain.polShtmCd == '02' ? 2 : 1"
                >
                  <template v-if="i === 0">
                    <select :name="'cfsFixdTypCd_' + index"
                            :id="'cfsFixdTypCd_' + index"
                            v-model="main.cfsFixdTypCd"
                            :disabled="true"
                            @change="fnChangeShowling(main.cfsFixdTypCd, index)"
                    >
                      <option value="01">{{ $t('msg.CSBK100.047') }}</option> <!-- Line -->
                      <option value="02">{{ $t('msg.CSBK100.048') }}</option> <!-- 자가 -->
                      <option value="03">{{ $t('msg.CSBK100.283') }}</option> <!-- 쇼링없음-->
                    </select>
                    <input type="text" :name="'cfsFixdEntrNm_' + index"
                           :id="'cfsFixdEntrNm_' + index"
                           v-model="main.cfsFixdEntrNm"
                           :readonly="main.cfsFixdEntrNm !== '02'"
                    > <!-- 선적항 CFS일 때 -->
                  </template>
                  <template v-else>
                    <p>-</p>
                  </template>
                </td>

                <td v-if="objBookingDetailMain.polShtmCd == '01'">
                  <div class="tbl_form">
                    <!-- 담당자 -->
                    <span class="dv col_3 font_12 text_left">
                      {{ $t('msg.CMBK100_M1.023_1') }}
                    </span>
                    <span class="dv">
                      <input type="text"
                             :name="'pickUpMan_' + index +'_' + i"
                             :id="'pickUpMan_' + index +'_' + i"
                             :value="cntr.pcupMan"
                             :disabled="true"
                      >
                    </span>
                  </div>
                  <div class="tbl_form mt5">
                    <!-- Tel -->
                    <span class="dv col_3 font_12 text_left">
                      {{ $t('msg.CSBK100.285') }}
                    </span>
                    <span class="dv">
                      <input type="text"
                             :name="'pickUpTel_' + index +'_' + i"
                             :id="'pickUpTel_' + + index +'_' + i"
                             :value="cntr.pcupTel"
                             :disabled="true"
                      >
                    </span>
                  </div>
                </td>
              </tr>
            </template>
          </table>
        </template>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a v-if="editFlag != 'Y'" class="button blue lg" href="#" @click.prevent="fnSplit($event)">{{ $t('msg.CSBK100.231') }}</a> <!-- Split  -->
        <a class="button blue lg" @click.prevent="fnDetail(bkgNo)">{{ $t('msg.CSBK100.320') }}</a> <!-- Booking Detail -->
        <a class="button gray lg float_right" @click="closePopup('Y')">{{ $t('msg.ONEX020P130.068') }}</a>
      </div>
    </div><!-- popup_cont -->
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div><!-- popup_wrap // -->
</template>

<script>

import { rootComputed } from '@/store/helpers'
import bookingSplit from '@/api/rest/trans/bookingSplit'
import trans from '@/api/rest/trans/trans'
import commons from '@/api/services/commons'
import EDatePicker from '@/components/common/EDatePicker'
import EInputNumber from '@/components/common/EInputNumber'

//더미 ROW를 위한 기본값 셋팅용
let defaultPcupReqDt = ''
let defaultPcupTime = ''
let defaultPcupMan = ''
let defaultPcupTel = ''
let defaultPcupCyNm = ''
let defaultPcupCyCd = ''

export default ({
  name: 'BookingSplitDetailPop',
  components: {
    EInputNumber,
    EDatePicker,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'pick-up-search-pop': () => import('@/pages/trans/popup/PickUpSearchPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: '',
          page: 'LIST'
        }
      }
    }
  },
  data: function () {
    return {
      customComponent: null,
      serverYear: '',
      serverMonth: '',
      serverDay: '',
      serverHour: '',
      serverMin: '',
      serverSecond: '',
      selectFunc: null,
      eta: '',
      etd: '',
      bkgNo: this.parentInfo.bkgNo,
      grsWtTon: '', //중량관련
      grsWtKg: '', //중량관련
      isKg: false, //중량관련
      isTon: false, //중량관련
      grsWt: '', //중량관련
      wtCvsCd: '', //중량관련
      wtAutoClcYn: '', //중량관련
      lang: '',
      cstCatCd: '',
      fwdrCstCd: '',
      objBookingDetailMain: {},
      objBookingDetailMainList: [],
      objBookingDetailContainer: [],
      objBookingDetailContainerList: [],
      splitDetailList: [],
      splitCntrList: [],
      restriction: [],
      bkgShprList: [], //미사용 추정(JPN 전용)
      fwdrList: [], //미사용 추정(JPN 전용)
      cstYn: false,
      cntrTypeList: [
        { cdNm: 'Dry', cd: 'GP' },
        { cdNm: 'HIGH CUBE', cd: 'HC' },
        { cdNm: 'REEFER', cd: 'RF' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH' },
        { cdNm: 'TANK', cd: 'TK' },
        { cdNm: 'OPEN TOP', cd: 'OT' },
        { cdNm: 'FLAT RACK', cd: 'FR' },
        { cdNm: 'SUPER RACK', cd: 'SR' }
      ],
      editFlag: 'N'
    }
  },
  computed: {
    ...rootComputed,
    bookingDate: function () {
      let result = ''
      if (!this.objBookingDetailMain) {
        return result
      } else {
        let bkgDt = this.objBookingDetailMain.bkgDt
        if (!bkgDt) {
          return result
        }
        result = bkgDt.substring(0, 4) + '-' + bkgDt.substring(4, 6) + '-' + bkgDt.substring(6, 8)

        return result
      }
    }
  },
  created () {
    commons.getServerTime().then(res => {
      this.serverYear = res.data.substring(0, 4)
      this.serverMonth = res.data.substring(4, 6)
      this.serverDay = res.data.substring(6, 8)
      this.serverHour = res.data.substring(8, 10)
      this.serverMin = res.data.substring(10, 12)
      this.serverSecond = res.data.substring(12, 14)
    })
    if (this.auth !== undefined) {
      this.lang = this.auth.serviceLang
      this.cstCatCd = this.auth.cstCatCd
    }
    if (this.memberDetail !== undefined) {
      this.fwdrCstCd = this.memberDetail.fwdrCstCd
    }

    if (this.lang === 'KOR') {
      this.cntrTypeList = this.cntrTypeList.filter(vo => vo.cd !== 'SR')
    }

    this.getBookingSplitInfo('N')
  },
  methods: {
    //[func]-부킹 split 상세 정보 가져오기
    async getBookingSplitInfo (editFlag) {
      //------GET BOOKING SPLIT DETAIL INFORMATION-------
      let result = await bookingSplit.getBookingSplitDetail({ bkgNo: this.bkgNo, editFlag: editFlag })

      //부킹대상 정보 가공
      this.objBookingDetailMain = result.data.objBookingDetailMain

      //중량정보 가공
      if (this.$ekmtcCommon.isNotEmpty(this.objBookingDetailMain.grsWt)) {
        const type = this.objBookingDetailMain.wtCvsCd === 'K' ? 'KG' : 'TON'
        const grsWt = this.objBookingDetailMain.grsWt
        if (type === 'TON') {
          this.grsWtTon = this.objBookingDetailMain.grsWt
          this.grsWtKg = Number(grsWt) * 1000
        } else {
          this.grsWtKg = this.objBookingDetailMain.grsWt
          this.grsWtTon = (Number(grsWt) * 1000) / 1000000
        }
      }

      //if (!this.objBookingDetailMain.cfsFixdTypCd) {
      if (this.objBookingDetailMain.polShtmCd === '02' && !this.objBookingDetailMain.cfsFixdTypCd) {
        this.objBookingDetailMain.cfsFixdTypCd = '01'
      }

      this.objBookingDetailMain.cboTerm = this.objBookingDetailMain.polShtmCd
      this.objBookingDetailMain.eachGrsWt = 0
      this.objBookingDetailMain.newCfsFixdTypCd = this.objBookingDetailMain.cfsFixdTypCd
      this.objBookingDetailMain.newCfsFixdEntrNm = this.objBookingDetailMain.cfsFixdEntrNm

      const defaultMain = {
        fixFlag: 'Y',
        eachGrsWt: 0,
        polShtmCd: this.objBookingDetailMain.polShtmCd,
        newCfsFixdTypCd: this.objBookingDetailMain.cfsFixdTypCd,
        newCfsFixdEntrNm: this.objBookingDetailMain.cfsFixdEntrNm
      }

      //두번째 스플릿 ROW는 삭제되면 안 되는 DEFAULT ROW 추가
      this.objBookingDetailMainList = [this.objBookingDetailMain, defaultMain]

      //스플릿 대상 컨테이너 가공
      this.objBookingDetailContainer = result.data.objBookingDetailContainer.map((item, index) => {
        if (item.pcupPic) {
          item.pcupMan = item.pcupPic.split(',')[0]
          item.pcupTel = item.pcupPic.split(',')[1]
        } else {
          item.pcupMan = ''
          item.pcupTel = ''
        }

        //새로운 스플릿 컨테이너 입력값 바인딩용(기존 데이터 유지를 위한 변수)
        item.newCntrTypCd = item.cntrTypCd
        item.newCntrSzCd = item.cntrSzCd
        item.newCntrQty = item.cntrQty
        item.oldpcupReqDt = item.pcupReqDt
        item.newPcupReqDt = item.pcupReqDt
        item.newPcupCyCd = item.pcupCyCd
        item.newPcupCyNm = item.pcupCyNm
        item.pickUpTime = 0
        item.newPickUpTime = item.pickUpTime

        //추가되는 컨테이너 픽업관련정보 기본값 셋팅(하단의 defaultContainer)
        if (index === 0) {
          defaultPcupReqDt = item.newPcupReqDt
          defaultPcupMan = item.pcupMan
          defaultPcupTel = item.pcupTel
          defaultPcupCyNm = item.pcupCyNm
          defaultPcupCyCd = item.pcupCyCd
          defaultPcupTime = item.pickUpTime
        }
        return item
      })

      //각 MAIN ROW에 매칭되도록 sequence 처리를 하지 않고 컨테이너 리스트를 다시 배열화하여 사용
      this.objBookingDetailContainerList = [[...this.objBookingDetailContainer], [this.getDefaultContainer()]]

      //SPLIT 요청 후 RESULT VIEW DATA
      if (editFlag === 'Y') {
        this.splitDetailList = result.data.splitDetailList
        this.splitCntrList = result.data.splitCntrList.map((item) => {
          if (item.pcupPic) {
            item.pcupMan = item.pcupPic.split(',')[0]
            item.pcupTel = item.pcupPic.split(',')[1]
          } else {
            item.pcupMan = ''
            item.pcupTel = ''
          }
          return item
        })
      }

      //운송-공장지역
      this.restriction = result.data.restriction

      //상단 ETA, ETD 정보 가공
      this.etd = this.getMonth('etd')
      this.eta = this.getMonth('eta')

      if (this.lang === 'JPN') {
        await this.initFwdrInfo()
      }
    },
    async initFwdrInfo () {
      await trans.getFwdrInfoList().then(res => {
        const result = res.data

        if (result !== undefined) {
          const fwdrList = result.fwdrList
          const bkgShprList = result.bkgShprList

          this.$set(this, 'fwdrList', fwdrList)
          this.$set(this, 'bkgShprList', bkgShprList)

          fwdrList.forEach(f => {
            if (f.fwdrCstNo === this.objBookingDetailMain.fwdrCstNo) {
              this.cstYn = true
            }
          })
        }
      })
    },
    //[func]-ETD, ETA 날짜값 computed 함수
    getMonth (etFlag) {
      let result = ''
      let et = ''
      let etMonth = ''
      let resultEtFront = ''
      let resultEtEnd = ''

      if (this.objBookingDetailMain.length === 0) {
        return result
      } else {
        if (etFlag === 'etd') {
          et = this.objBookingDetailMain.etd
        } else {
          et = this.objBookingDetailMain.eta
        }

        if (this.$ekmtcCommon.isNotEmpty(et)) {
          //etMonth = this.objBookingDetailMain.etd.substring(4, 6)
          etMonth = et.substring(4, 6)
        }
      }

      if (this.$ekmtcCommon.isEmpty(et)) {
        return
      }

      resultEtFront = et.substring(0, 4)
      resultEtEnd = et.substring(6, 8) + ' ' + et.substring(8, 10) + ':' + et.substring(10, 12)

      let month = ''
      if (this.lang === 'ENG') {
        if (etMonth === '01') {
          month = '.Jan.'
        } else if (etMonth === '02') {
          month = '.Feb.'
        } else if (etMonth === '03') {
          month = '.Mar.'
        } else if (etMonth === '04') {
          month = '.Apr.'
        } else if (etMonth === '05') {
          month = '.May.'
        } else if (etMonth === '06') {
          month = '.Jun.'
        } else if (etMonth === '07') {
          month = '.Jul.'
        } else if (etMonth === '08') {
          month = '.Aug.'
        } else if (etMonth === '09') {
          month = '.Sep.'
        } else if (etMonth === '10') {
          month = '.Oct.'
        } else if (etMonth === '11') {
          month = '.Nov.'
        } else if (etMonth === '12') {
          month = '.Dec.'
        }

        result = resultEtFront + month + resultEtEnd
      } else {
        result = resultEtFront + '.' + et.substring(4, 6) + '.' + et.substring(6, 8) + ' ' + et.substring(8, 10) + ':' + et.substring(10, 12)
      }

      return result
    },
    //[func]-split 시작
    fnValidation () {
      //qty 제약 체크
      if (!this.fnCntrQtyCheck()) {
        return false
      }

      //픽업정보 제약 체크
      if (!this.fnPickUpCheck()) {
        return false
      }

      //size, type 제약 체크
      if (!this.fnCntrTypCheck()) {
        return false
      }

      //중량 제약 체크
      if (!this.fnGrsWtCheck()) {
        return false
      }
      return true
    },
    //[func]-QTY(TEU) 체크
    fnCntrQtyCheck () {
      //오리지날 체크
      let originalQtyTotal = 0
      this.objBookingDetailContainer.map((item) => {
        if (item.cntrSzCd === '20') {
          originalQtyTotal += Number(item.cntrQty)
        } else {
          originalQtyTotal += Number(item.cntrQty) * 2
        }
      })

      //스플릿대상 체크
      let splitedQtyTotal = 0

      for (let i = 0; i < this.objBookingDetailContainerList.length; i++) {
        for (let j = 0; j < this.objBookingDetailContainerList[i].length; j++) {
          let item = this.objBookingDetailContainerList[i][j]
          //container Size 미선택 상태일 시
          if (!item.newCntrSzCd) {
            item.newCntrSzCd = '20'
          }

          //스플릿 대상 컨테이너 중 QTY 미입력 있는 경우
          if (!item.newCntrQty || item.newCntrQty === '0' || item.newCntrQty === 0) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.288')) //컨테이너 정보를 확인하세요.
            document.getElementById('cntrQty_' + i + '_' + j).focus()
            return false
          }

          if (item.newCntrSzCd === '20') {
            splitedQtyTotal += Number(item.newCntrQty)
          } else {
            splitedQtyTotal += Number(item.newCntrQty) * 2
          }
        }
      }

      if (originalQtyTotal < splitedQtyTotal) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.289')) //분할되는 컨테이너 수량이 상이하오니 재확인 부탁드립니다.
        return false
      }
      return true
    },
    async fnSplit () {
      if (this.fnValidation()) {
        //저장하기
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.290'), useConfirmBtn: true })) { //저장하시겠습니까
          let mainSeq = 0
          this.objBookingDetailContainerList.map((container) => {
            container.map((item) => {
              item.mainSeq = mainSeq
            })
            mainSeq += 1
          })
          const containerList = this.objBookingDetailContainerList.flat()
          const formData = {
            bkgNo: this.bkgNo,
            spcShiprYn: this.objBookingDetailMainList[0].spcShiprYn,
            detailList: this.objBookingDetailMainList,
            cntrList: containerList
          }
          bookingSplit.startBookingSplit(formData).then((result) => {
            if (result.data === 1) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.291')) //정상적으로 분할되었습니다.
              this.editFlag = 'Y'
              this.getBookingSplitInfo(this.editFlag)

              if (this.$route.name === 'progress') {
                this.$emit('selectFunc')
              }
            } else {
               this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.292')) //분할하지 못했습니다. 관리자에게 문의하세요.
            }
          })
        }
      }
    },
    //[func]-SIZE, TYPE 벨리데이션
    fnCntrTypCheck () {
      let containerList = this.objBookingDetailContainerList
      for (let i = 0; i < containerList.length; i++) {
        for (let j = 0; j < containerList[i].length; j++) {
          if (containerList[i][j].newCntrSzCd === '20' && containerList[i][j].newCntrTypCd === 'HC') {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.293')) //컨테이너 SIZE가 20일때는 HIGH  CUBE를 선택할 수 없습니다.
            document.getElementById('cntrTypCd_' + i + '_' + j).focus()
            return false
          }
        }
      }
      return true
    },
    //[func]-픽업관련 벨리데이션
    fnPickUpCheck () {
      let cboTerm = this.objBookingDetailMain.cboTerm

      //3.???벨리데이션에서 사용
      let containerArr = []
      let pickUpArr = []
      let sizeTemp = []
      let typeTemp = []
      let pickUpTemp = []

      const frm = document.querySelector('.booking_split_detail')
      const firstPcupCyCd = this.objBookingDetailContainerList[0][0].newPcupCyCd

      //픽업장소 제약
      for (let i = 0; i < this.objBookingDetailContainerList.length; i++) {
        for (let j = 0; j < this.objBookingDetailContainerList[i].length; j++) {
          let pickUpDate = this.objBookingDetailContainerList[i][j].newPcupReqDt
          let containerSize = this.objBookingDetailContainerList[i][j].cntrSzCd
          let containerType = this.objBookingDetailContainerList[i][j].cntrTypCd
          let pickUpCyCd = this.objBookingDetailContainerList[i][j].pcupCyCd
          let newPickUpCyCd = this.objBookingDetailContainerList[i][j].newPcupCyCd
          let shprVanYn = this.objBookingDetailContainerList[i][j].shprVanYn

          pickUpCyCd = this.$ekmtcCommon.isNotEmpty(pickUpCyCd) ? pickUpCyCd : ''
          let str = ''
          //1.pickUpDate 벨리데이션
          if (cboTerm === '01' || cboTerm === '03') {
            str = this.$t('msg.CSBK100.294') //Pick-Up 일시를 확인하세요
          } else {
            str = this.$t('msg.CSBK100.295') //입고 일자를 확인하세요
          }

          if (this.$ekmtcCommon.isEmpty(pickUpDate) && shprVanYn !== 'Y') {
            this.$ekmtcCommon.alertDefault(str)
            document.getElementById('pickUpDate_' + i + '_' + j).focus()
            return false
          }

          if (!this.fnPickUpDateCheck(pickUpDate, i, j) && shprVanYn !== 'Y') {
            return false
          }

          if (this.objBookingDetailMain.owtrYn !== 'Y' || this.objBookingDetailMain.polShtmCd === '02') {
            this.objBookingDetailContainerList[i][j].pcupCyCd = this.objBookingDetailContainerList[0][0].pcupCyCd
          }

          if (firstPcupCyCd !== newPickUpCyCd) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.296'))
            return false
          }

          //3.???벨리데이션
          if (containerSize) {
            if (cboTerm === '01') {
              sizeTemp = containerSize.trim()
              typeTemp = containerType.trim()
              pickUpTemp = pickUpCyCd.trim()
            }

            if (pickUpTemp) {
              containerArr[containerArr.length] = sizeTemp + '_' + typeTemp
              pickUpArr[pickUpArr.length] = pickUpTemp
            }
          }
        }
      }
      return true
    },
    //[func]-픽업 일자 벨리데이션
    fnPickUpDateCheck (pickUpDate, index, subIndex) {
      if (!pickUpDate) {
        return false
      }

      let cboTerm = this.objBookingDetailMain.cboTerm
      let bkgDt = this.objBookingDetailMain.bkgDt
      let bookingDateValue = ''
      let bookingDateArr = []

      if (!bkgDt) {
        bookingDateValue = bkgDt.substring(0, 4) + '-' + bkgDt.substring(4, 6) + '-' + bkgDt.substring(6, 8)
      }
      if (bookingDateValue) {
        bookingDateArr = bookingDateValue.split('-')
      }

      let docuPickUpDate = document.getElementById('pickUpDate_' + index + '_' + subIndex).value
      if ((docuPickUpDate.length > 0 && docuPickUpDate.length < 8) || (pickUpDate.length > 0 && pickUpDate.length < 8)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.297')) //날짜형식에 맞지 않습니다.
        document.getElementById('pickUpDate_' + index + '_' + subIndex).value = ''
        return false
      }

      let pickUpTime = ''
      if (cboTerm === '01' || cboTerm === '03') {
        pickUpTime = this.objBookingDetailContainerList[index][subIndex].newPickUpTime
        if (!pickUpTime) {
          pickUpTime = '09'
        }
      } else {
        pickUpTime = '09'
      }

      let tempPickUpDate = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))
      let today = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
      let today1 = new Date(today.getFullYear(), today.getMonth(), today.getDate())

      if (tempPickUpDate < today1) {
        if (cboTerm === '01' || cboTerm === '03') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.298')) //Pick up 일자는 현재일자 이후로 입력해주세요.
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.299')) //입고일자는 현재일자 이후로 입력해주세요.
        }
        this.objBookingDetailContainerList[index][subIndex].newPcupReqDt = ''
        document.getElementById('pickUpDate_' + index + '_' + subIndex).value = ''
        return false
      }

      /**
      라인 운송의 경우, 입력하고자 하는 픽업일은 부킹일 기준 전날 15시까지 해야함.
        (EX:월요일 14:00 화요일로 픽업일 설정 가능
            월요일 16:00 수요일 부터 픽업일 가능)
      자가 운송의 경우, 픽업일 당일 부킹 가능"
      **/
      if (cboTerm === '01' || cboTerm === '03') {
        let today1 = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
        let today = new Date(today1.getFullYear(), today1.getMonth(), today1.getDate() + 1) //내일날짜
        let today2 = new Date(today1.getFullYear(), today1.getMonth(), today1.getDate()) //오늘날짜

        //운송이 'Line'일 때
        if (this.objBookingDetailMain.owtrYn === 'N') {
          let tempPickUpDate = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))
          let bkgDate = new Date(Number(bookingDateArr[0]), Number(bookingDateArr[1]) - 1, Number(bookingDateArr[2]) + 1)
          let month = ''
          let day = ''

          month = tempPickUpDate.getMonth() + 1
          if (String(month).length === 1) {
            month = '0' + month
          }
          day = tempPickUpDate.getDate()
          if (String(day).length === 1) {
            day = '0' + day
          }

          let pickUpValue = tempPickUpDate.getFullYear() + '' + month + '' + day

          month = bkgDate.getMonth() + 1
          if (String(month).length === 1) {
            month = '0' + month
          }

          if (tempPickUpDate < today) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.300')) //Line 운송의 경우, 당일 Pick up은 불가능합니다.
            this.objBookingDetailContainerList[index][subIndex].newPcupReqDt = ''
            document.getElementById('pickUpDate_' + index + '_' + subIndex).value = ''
            return false
          }

          //오늘날짜구하기
          month = today.getMonth() + 1
          if (String(month).length === 1) {
            month = '0' + month
          }
          day = today.getDate()
          if (String(day).length === 1) {
            day = '0' + day
          }
          let toDate = today.getFullYear() + '' + month + '' + day

          if (pickUpValue === toDate) {
            if (Number(today1.getHours()) >= 15) {
              this.$ekmtcCommon.alertDefault(pickUpValue.substring(0, 4) + this.$t('msg.CSBK100.321') + pickUpValue.substring(4, 6) + this.$t('msg.CSBK100.322') + pickUpValue.substring(6, 8) + this.$t('msg.CSBK100.323') + '\n\n' + this.$t('msg.CSBK100.324'))
              document.getElementById('pickUpDate_' + index + '_' + subIndex).value = ''
              return false
            }
          }

        //운송이 '자가'일 때
        } else {
          let tempPickUpDate = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))
          //오늘날짜도 가능함.
          if (tempPickUpDate < today2 && pickUpDate.length === 8) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.301')) //픽업일은 현재일자 이후로만 가능합니다.
            document.getElementById('pickUpDate_' + index + '_' + subIndex).value = ''
            return false
          }
        }

        /***************************
         * 반입마감일 입항일 체크로직
         ***************************/
        let flag = false
        let cgoDate = ''
        let cgoRest = this.restriction[0].cgoRest
        //서류 마감일이 있을 때
        if (this.$ekmtcCommon.isNotEmpty(cgoRest) && cgoRest >= 8) {
          cgoDate = new Date(Number(cgoRest.substring(0, 4)), Number(cgoRest.substring(4, 6)) - 1, Number(cgoRest.substring(6, 8)))
          flag = true
        } else {
          let eta = this.objBookingDetailMain.polEta
          if (eta) {
            cgoDate = new Date(Number(eta.substring(0, 4)), Number(eta.substring(4, 6)) - 1, Number(eta.substring(6, 8)))
            flag = true
          }
        }

        if (!flag) {
          return true
        }

        let month = ''
        let day = ''

        month = cgoDate.getMonth() + 1
        if (String(month).length === 1) {
          month = '0' + month
        }

        day = cgoDate.getDate()
        if (String(day).length === 1) {
          day = '0' + day
        }
        let cgoValue = cgoDate.getFullYear() + '' + month + '' + day

        month = today.getMonth() + 1
        if (String(month).length === 1) {
          month = '0' + month
        }

        day = today.getDate()
        if (String(day).length === 1) {
          day = '0' + day
        }
        let todayValue = today.getFullYear() + '' + month + '' + day

        //라인일때
        if (this.objBookingDetailMain.owtrYn === 'N') {
          if (cgoValue === todayValue) {
            if (Number(today1.getHours()) >= 15) {
              this.$ekmtcCommon.alertDefault(this.$t('js.booking.018')) //Pick up일자 설정이 불가능합니다. 담당자에게 문의하세요.
              return false
            }
          }
        //자가일때
        } else {
          if (cgoDate < today2) {
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.018')) //Pick up일자 설정이 불가능합니다. 담당자에게 문의하세요.
            return false
          }
        }
      } else if (cboTerm === '02') {
        let etaDateString = this.objBookingDetailMain.polEta
        let etaDate = new Date(Number(etaDateString.substring(0, 4)), Number(etaDateString.substring(4, 6)) - 1, Number(etaDateString.substring(6, 8)))
        let tempPickUpDate = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)), Number(pickUpDate.substring(pickUpTime)))

        if (etaDate < tempPickUpDate) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.302')) //입고일자는 입항일 당일까지 입력가능합니다.
          document.getElementById('pickUpDate_' + index + '_' + subIndex).value = ''
          return false
        }
        return true
      }

      /**
       Pick up 일은 선박의 POL 입항일 당일까지 입력 가능 (우선, 반입마감일이 있으면 반입 마감일까지)
      **/
      if (this.lang === 'KOR') {
        if (pickUpDate) {
          let cgoRest = this.restriction[0].cgoRest
          //반입마감일이 없을 때
          if (this.$ekmtcCommon.isNotEmpty(cgoRest) && cgoRest.length >= 8) {
            let cgoDate = new Date(Number(cgoRest.substring(0, 4)), Number(cgoRest.substring(4, 6)) - 1, Number(cgoRest.substring(6, 8)), Number(cgoRest.substring(8, 10)))
            let tempPickupDate2 = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)), Number(pickUpTime))

            if (cgoDate < tempPickupDate2) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.303')) //Pickup 일자는 서류마감일시까지 입력가능합니다.
              this.objBookingDetailContainerList[index][subIndex].newPcupReqDt = ''
              document.getElementById('pickUpDate_' + index + '_' + subIndex).value = ''

              if (cboTerm === '01' || cboTerm === '03') {
                if (Number(cgoDate.getHours()) <= 9) {
                  this.objBookingDetailContainerList[index][subIndex].newPickUpTime = '09'
                } else if (Number(cgoDate.getHours()) >= 17) {
                  this.objBookingDetailContainerList[index][subIndex].newPickUpTime = '17'
                } else {
                  this.objBookingDetailContainerList[index][subIndex].newPickUpTime = cgoDate.getHours()
                }
              }

              return false
            }
          //반입마감일이 있을 때
          } else {
            let eta = this.objBookingDetailMain.polEta
            let etaDate = new Date(Number(eta.substring(0, 4)), Number(eta.substring(4, 6)) - 1, Number(eta.substring(6, 8), Number(eta.substring(8, 10))))
            let pickupDate = new Date(Number(pickUpDate.substring(0, 4)), Number(pickUpDate.substring(4, 6)) - 1, Number(pickUpDate.substring(6, 8)))

            console.log()
            if (this.$ekmtcCommon.isNotEmpty(eta) && etaDate < pickupDate && pickUpDate.length >= 8) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.304')) //Pickup 일자는 입항일 당일까지 입력가능합니다.
              this.objBookingDetailContainerList[index][subIndex].newPcupReqDt = ''
              document.getElementById('pickUpDate_' + index + '_' + subIndex).value = ''

              if ((cboTerm === '01' || cboTerm === '03') && this.$ekmtcCommon.isNotEmpty(cgoRest)) {
                if (Number(cgoDate.getHours()) <= 9) {
                  this.objBookingDetailContainerList[index][subIndex].newPickUpTime = '09'
                } else if (Number(cgoDate.getHours()) >= 17) {
                  this.objBookingDetailContainerList[index][subIndex].newPickUpTime = '17'
                } else {
                  this.objBookingDetailContainerList[index][subIndex].newPickUpTime = cgoDate.getHours()
                }
              }

              return false
            }
          }
        }
      } else {
        // 해외의 경우 출항일까지 픽업날짜 세팅 가능
        if (pickUpDate) {
          let etd = this.objBookingDetailMain.etd
          let etdDate = new Date(Number(etd.substring(0, 4)), Number(etd.substring(4, 6)) - 1, Number(etd.substring(6, 8), Number(etd.substring(8, 10))))
          let pickUp = this.$ekmtcCommon.getStrToDate(pickUpDate)

          if (this.$ekmtcCommon.isNotEmpty(etd) && etdDate <= pickUp && pickUpDate.length >= 8) {
            this.$ekmtcCommon.alertDefault(this.$t('js.booking.018'))
            return false
          }
        }
      }
      return true
    },
    //[func]-중량 제약 벨리데이션
    fnGrsWtCheck () {
      let msg10Ton = 0
      let msg25Ton = 0
      for (let i = 0; i < this.objBookingDetailContainerList.length; i++) {
        let qty = 0
        for (let j = 0; j < this.objBookingDetailContainerList[i].length; j++) {
          let item = this.objBookingDetailContainerList[i][j]
          qty += Number(item.newCntrQty)
        }

        let grsWt = this.objBookingDetailMainList[i].eachGrsWt
        if (grsWt) {
          let avgWt = Number(grsWt) / qty

          if (avgWt > 40000) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.305') + '\n' + this.$t('msg.CSBK100.306') + '\n' + this.$t('msg.CSBK100.307')) //컨테이너 당 평균 중량이 40톤 이상입니다.\nG.Weight 항목을 확인하여 수정해 주시기 바랍니다.\n평균 중량이 40톤 이상인 경우, 업무 담당자에게 연락 주시기 바랍니다.
            return false
          }

          if (avgWt < 10000) {
            msg10Ton += 1
          }

          if (avgWt > 25000) {
            msg25Ton += 1
          }
        }
      }

      if (msg10Ton > 0) {
        if (!confirm(this.$t('msg.CSBK100.308') + '\n' + this.$t('msg.CSBK100.309'))) { //G.Weight 항목을 확인해 주십시오. 평균 중량이 10톤 이하입니다.\n수정없이 진행하시겠습니까?
          return false
        }
      }

      if (msg25Ton > 0) {
        if (!confirm(this.$t('msg.CSBK100.310') + '\n' + this.$t('msg.CSBK100.309'))) { //G.Weight 항목을 확인해 주십시오. 평균 중량이 25톤 이상입니다.\n수정없이 진행하시겠습니까?
          return false
        }
      }

      return true
    },
    //[func]-일자에 따른 콤바인 변경 가능여부 벨리데이션
    fnChangeType (event, type) {
      const types = 'txtManName|txtManTel|cboTerm|zip1|zip2|schedule|cboFactory'

      let today1 = new Date(Number(this.serverYear), Number(this.serverMonth) - 1, Number(this.serverDay), Number(this.serverHour), Number(this.serverMin), Number(this.serverSecond))
      let today = new Date(today1.getYear(), today1.getMonth(), today1.getDate() + 1) //내일 날짜를 구해서 Pickup일시와 비교한다.

      let month = today.getMonth() + 1
      if (String(month).length === 1) {
        month = '0' + month
      }
      let day = today.getDate()
      if (String(day).length === 1) {
        day = '0' + day
      }

      let toDateValue = today.getYear() + '' + month + '' + day

      if (!this.objBookingDetailMain.polTrmlCd) {
        return true
      }

      //처음 Booking할 때 pick up일자와 내일날짜를 비교한다.
      let compareValue = ''
      if (this.objBookingDetailMain.cboTerm === '01' || this.objBookingDetailMain.cboTerm === '03') {
        //운송구분 Line -> 자가 변경 시
        if (this.selectedOwtrYn === 'N') {
          // this.objBookingDetailContainer.forEach((item) => {
          for (let i = 0; i < this.objBookingDetailContainer.length; i++) {
            compareValue = this.objBookingDetailContainer[i].pcupReqDt
            let compareDate = new Date(Number(compareValue.substring(0, 4)), Number(compareValue.substring(4, 6)) - 1, Number(compareValue.substring(6, 8)))

            if (toDateValue === compareValue) {
              if (Number(today1.getHours()) >= 15) {
                if (type === 'cboTransType') {
                  event.target.blur()
                  this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.311') + '\n\n' + this.$t('msg.CSBK100.312')) //내일 Pick up되는 컨테이너가 있습니다. 15시 이후엔 변경 불가\n\n유선으로 문의하세요.(02-311-6114)
                } else if (types.indexOf(type) > -1) {
                  event.target.blur()
                  this.$ekmtcCommon.alertDefault(this.$t('art.CMATK182')) //Pick up일자 하루전 15시까지만 변경 가능합니다.
                  if (type === 'cboFactory') {
                    document.getElementById('txtFactory').blur()
                  }
                  return false
                } else {
                  event.target.blur()
                  this.$ekmtcCommon.alertDefault(this.$t('art.CMATK182')) //Pick up일자 하루전 15시까지만 변경 가능합니다.
                  return false
                }
                return false
              }
            }

            if (compareDate < today1) {
              if (type === 'cboTransType') {
                event.target.blur()
                this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.313') + '\n\n' + this.$t('msg.CSBK100.312')) //Pick up일시가 지난 컨테이너가 있습니다. 수정불가\n\n유선으로 문의하세요.(02-311-6114)
              } else if (types.indexOf(type) > -1) {
                event.target.blur()
                this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.314')) //Pick up일자 하루전 15시까지만 변경 가능합니다.
                if (type === 'cboFactory') {
                  document.getElementById('txtFactory').blur()
                }
              }
              return false
            }
          }
        }
      }
      return true
    },
    //[func]-Booking Detail로 이동
    async fnDetail (bkgNo) {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.315'), useConfirmBtn: true })) { //부킹 수정 화면으로 이동합니다.
        this.closePopup('Y')
        if (this.parentInfo.page !== 'DETAIL') {
          this.$router.push({ name: 'booking-edit', params: { bkgNo: bkgNo } }).catch(() => {})
        } else {
          this.$emit('selectFunc', bkgNo)
        }
      }
    },
    //[func]-SPLIT으로 나눌 추가 부킹 추가 및 삭제
    fnChangeMain (flag, index) {
      if (flag === 'PLUS') {
        const defaultMain = {
          fixFlag: '',
          newCfsFixdTypCd: this.objBookingDetailMain.cfsFixdTypCd,
          newCfsFixdEntrNm: this.objBookingDetailMain.cfsFixdEntrNm,
          polShtmCd: this.objBookingDetailMain.polShtmCd
        }
        this.objBookingDetailMainList.push(defaultMain)
        this.objBookingDetailContainerList.push([this.getDefaultContainer()])
      } else {
        this.objBookingDetailMainList.splice(index, 1)
        this.objBookingDetailContainerList.splice(index, 1)
      }
    },
    //[func]-SPLIT으로 나눌 추가 부킹 컨테이너 추가 및 삭제
    fnChangeSub (flag, index, subIndex) {
      if (flag === 'PLUS') {
        this.objBookingDetailContainerList[index].push(this.getDefaultContainer())
      } else {
        this.objBookingDetailContainerList[index].splice(subIndex, 1)
      }
    },
    //[func]-SPLIT으로 나눌 추가 부킹 컨테이너 기본값 가져오기
    getDefaultContainer () {
      const defaultContainer = {
        bkgNo: '',
        cgoTypeCd: '',
        cntrSzCd: '',
        newCntrTypCd: 'GP',
        newCntrSzCd: '20',
        newCntrQty: '',
        newPcupReqDt: defaultPcupReqDt,
        newPickUpTime: defaultPcupTime,
        pcupCyNm: defaultPcupCyNm,
        newPcupCyNm: defaultPcupCyNm,
        pcupCyCd: defaultPcupCyCd,
        newPcupCyCd: defaultPcupCyCd,
        pcupMan: defaultPcupMan,
        pcupTel: defaultPcupTel,
        polShtmCd: this.objBookingDetailMain.polShtmCd,
        shprVanYn: this.objBookingDetailContainer[0].shprVanYn
      }
      return defaultContainer
    },
    //[func]-중량 입력 벨리데이션
    chkGrossWeight (value, index) {
      let val = ''
      if (value) {
        val = value.replace(/,/g, '')
      }

      if (!val) {
        return
      }

      let array = []
      array = val.split('.')

      if ((array.length > 1 && array[1].length > 3) || array.length > 2) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.316')) //소수점이하 3자리까지만 가능합니다.
        document.getElementById('grsWt_' + index).value = ''
        this.objBookingDetailMainList[index].eachGrsWt = ''
        document.getElementById('grsWt_' + index).focus()
        return
      }

      let vNormal = /[^0-9.]/g
      if (vNormal.test(val) || isNaN(parseInt(val))) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.317')) //숫자만 입력하세요.
        document.getElementById('grsWt_' + index).value = ''
        this.objBookingDetailMainList[index].eachGrsWt = ''
        document.getElementById('grsWt_' + index).focus()
        return
      }

      let length = array[0].length
      if (length > 10) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.318')) //정수는 10자리까지 입력가능합니다.
        document.getElementById('grsWt_' + index).value = ''
        this.objBookingDetailMainList[index].eachGrsWt = ''
        document.getElementById('grsWt_' + index).focus()
        return
      }
      document.getElementById('grsWt_' + index).value = val
    },
    //[func]-쇼링 선택시
    fnChangeShowling (value, index) {
      if (value === '01') {
        this.objBookingDetailMainList[index].newCfsFixdEntrNm = this.$t('msg.CSBK100.319') //KMTC 라인쇼링
        document.getElementById('cfsFixdEntrNm_' + index).value = this.$t('msg.CSBK100.319') //KMTC 라인쇼링
        document.getElementById('cfsFixdEntrNm_' + index).readOnly = true
      } else if (value === '02') {
        this.objBookingDetailMainList[index].newCfsFixdEntrNm = ''
        document.getElementById('cfsFixdEntrNm_' + index).value = ''
        document.getElementById('cfsFixdEntrNm_' + index).readOnly = false
      } else {
        this.objBookingDetailMainList[index].newCfsFixdEntrNm = ''
        document.getElementById('cfsFixdEntrNm_' + index).value = ''
        document.getElementById('cfsFixdEntrNm_' + index).readOnly = true
      }
      this.$set(this.objBookingDetailMainList[index], index, this.objBookingDetailMainList[index].newCfsFixdEntrNm)
    },
    //[func]-팝업에서 선택한 값 셋팅
    pickUpSelect (obj) {
      const containerVo = this.objBookingDetailContainerList[obj.selectIdx.split('_')[0]][obj.selectIdx.split('_')[1]]

      containerVo.newPcupCyCd = obj.cyCd
      containerVo.newPcupCyNm = obj.cyNm
      containerVo.pcupMan = obj.picNm
      containerVo.pcupTel = obj.picTel
    },
    //[func]-운송이 자가일 때
    fnPickUpPlace (index, subIndex) {
      //운송이 LINE인 경우에는 선택 호출 X
      if (this.objBookingDetailMain.owtrYn === 'N') {
        return
      }

      //objBookingContainer에서 사이즈, 타입, 수량 가져옴
      let splitContainer = this.objBookingDetailContainerList[index][subIndex]
      let containerSize = splitContainer.newCntrSzCd
      let containerType = splitContainer.newCntrTypCd
      let containerPickUpDate = splitContainer.newPcupReqDt.trim()

      //objBookingMain에서 선적항, 선적터미널 가져옴
      let splitMain = this.objBookingDetailMain
      let polPortCd = splitMain.polPortCd

      if (!polPortCd) {
        polPortCd = 'USN'
      }

      //컨테이너 픽업일자가 없을 때
      if (!containerPickUpDate) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.021')) //Pickup 날짜를 입력해주세요!
        document.getElementById('pickUpDate_' + index + '_' + subIndex).select()
        return
      }

      //컨테이너 픽업일자가 형식에 안 맞을 때
      if (containerPickUpDate.length < 8) {
        this.$ekmtcCommon.alertDefault(this.$t('js.booking.022')) //날짜 자릿수는 8자리입니다.
        document.getElementById('pickUpDate_' + index + '_' + subIndex).select()
        return
      }

      this.popParams = {
        cntrSize: containerSize,
        cntrType: containerType,
        polPortCd: polPortCd,
        pickupDate: containerPickUpDate,
        selectRow: index + '_' + subIndex
      }

      this.selectFunc = this.pickUpSelect

      this.openPopup('pick-up-search-pop')
    },
    //[func]-팝업 OPEN
    openPopup (compNm) {
      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.booking_split_detail .popup_dim')
    },
    //[func]-팝업 CLOSE
    closePopup (flag) {
      this.customComponent = null
      this.selectFunc = null
      if (flag === 'Y') {
        this.$emit('close')
      } else {
        this.$ekmtcCommon.layerClose('.booking_split_detail .popup_dim')
      }
    }
  }
})
</script>
